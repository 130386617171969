<template>
  <div class="maladie">
    {{ maladie }}
  </div>
</template>
  
  <script>
  import {mapGetters} from 'vuex'
  export default {
      props : {
          object: {type: Object, required: true}
      },
      data(){
          return {
  
          }
      },
      methods: {
          
      },
      computed: {
          ...mapGetters({
            maladies: 'sanitaire/maladies'
          }),
          maladie(){
            let m = this.maladies.find(item => item.uid === this.object.maladie)
            if(m) return m.libelle
            return '-'
          }
      }
  
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>